import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext,
    TimeInput
} from 'react-admin';
import { Divider, Box, Grid  } from '@mui/material';
import { roles } from './roles';

export const AccountInputs = ({link, username}) => {
    const record = useRecordContext();

    return (<Box flex="1" mt={-1}>
        {!username || localStorage.getItem('role_id') < 2 || (localStorage.getItem('role_id') <= 2 && localStorage.getItem('id') !== record.id) ? <TextInput label="Имя пользователя (латиницей)" source="username" fullWidth validate={required()}/> : null}
        {link !== "edit" ? <TextInput label="Пароль" source="password" fullWidth validate={required()}/> : null}
        {link == "edit" && (localStorage.getItem('role_id') < 2 || localStorage.getItem('id') === record.id) ?
        <Box flex="1" flexDirection="column">
        <TextInput label="Старый пароль (вводить только если хотите сменить пароль)" source="prev_password" fullWidth /> 
        <TextInput label="Новый пароль (вводить только если хотите сменить пароль)" source="new_password" fullWidth /> 
        </Box>
        : null}

        <ReferenceInput source="company_id" reference="companies">
                <AutocompleteInput label="Компания" optionText={(choice) => `${choice.name}`} helperText="" fullWidth sx={{ marginTop: 2 }} />
        </ReferenceInput>

        <h3>Основные данные</h3>

         <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Имя" source="first_name" fullWidth validate={required()} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Фамилия и/или Отчество" source="last_name" fullWidth validate={required()} />
            </Grid>
        </Grid>

        <Divider />

        {/*<h3>Контактные данные</h3>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Email" source="email" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Telegram @username" source="telegram_username" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Номер телефона" source="phone" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Добавочный номер" source="phone_extension" fullWidth />
            </Grid>
        </Grid>

        <Divider />*/}
        
        <h3>Должностные данные</h3>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <SelectInput
                label="Роль в системе"
                source="role"
                choices={localStorage.getItem('role_id') < 2 ? roles : roles.slice(1)}
                validate={required()} 
                fullWidth 
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Название должности" source="position" fullWidth />
            </Grid>
        </Grid>

        {/*<h3>График работы (автовключение-отключение лидов)</h3>

        <BooleanInput label="Подключен к лидам (главный переключатель)" source="connectedToLeads" validate={required()} />*/}
        


    </Box>);
};

const Spacer = () => <Box width={20} component="span" />;
