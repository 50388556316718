import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import '../crm.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';

const ListContent = () => {
    const {
        data,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    const now = Date.now();

    return (
        <>
            <List>
                {data.map(obj => (
                    <RecordContextProvider key={obj.id} value={obj}>
                        <ListItem
                            button
                            component={Link}
                            to={`/entities/${obj.id}`}
                        >
                            <ListItemText
                                primary={obj.name}
                            />
                            <EditButton />
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
        </>
    );
};

const ListActions = () => (
    <TopToolbar>
        <CreateButton
            variant="contained"
            label="Новая таблица"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

const EntityList = () => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            actions={localStorage.getItem('role_id') <= 3 ? <ListActions /> : null}
            perPage={50}
            pagination={<Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />}
        >
            <ListContent />
        </RaList>
    ) : null;
};

export default EntityList;


