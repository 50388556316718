import * as React from 'react';
import {
    Show,
    SimpleListLoading,
    ReferenceField,
    TextField,
    useListContext,
    ExportButton,
    SortButton,
    EditButton,
    TopToolbar,
    CreateButton,
    Datagrid,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    RecordContextProvider,
    DateField,  ShowBase, useShowContext,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Divider,
    Typography, Box, Card, CardContent,
} from '@mui/material';
import '../crm.css';
import { ColorField, ColorInput } from 'react-admin-color-picker';
import { diffJson  } from 'diff';

const LogShow = () => (
    <ShowBase>
        <LogShowContent />
    </ShowBase>
);


const LogShowContent = () => {
const { record, isLoading } = useShowContext();
    if (isLoading || !record) return null;
    
    var differences;
    if(record.before_action && record.info){
      const previousObject = JSON.parse(record.before_action);
      const currentObject = JSON.parse(record.info);

      // Use diff library to find differences
      differences = diffJson(previousObject, currentObject);
    }

    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Card>
                    <CardContent>
                        <Box display="flex" flexDirection="column">
                            <Box flex="1">
                                <Typography variant="h5">
                                    {record.history}
                                </Typography>
                            </Box>
                            <Box flex="1">
                                <Typography variant="h6">
                                    {record.action} {record.table}
                                </Typography>
                            </Box>
                            <Box flex="1">
                                <Typography variant="body1">
                                    { new Date(record.date_time).toLocaleString()}, {' '} 
                                </Typography>
                            </Box>
                            <Divider />
                            Сущность до действия:
                            <TextField source="before_action" />
                            <Divider />
                            Действие:
                            <TextField source="info" />
                            <Divider />
                             {differences ? differences.map((part, index) => {
        const style = part.added ? { backgroundColor: '#aaffaa' } : part.removed ? { backgroundColor: '#ffaaaa' } : null;
        return <span key={index} style={style}>{part.value}</span>;
      }) : null}
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

export default LogShow;