import * as React from 'react';
import { CreateBase, Form, Toolbar, useDataProvider } from 'react-admin';
import { Card, CardContent, Box, Avatar } from '@mui/material';

import { AccountInputs } from './AccountInputs';
//const existingRecord = await dataProvider.getOne('entities', { id });
const AccountCreate = () => {
    const dataProvider = useDataProvider();
    
    return(
    <CreateBase
        redirect="show"
        mutationMode="optimistic"
        transform={async (data) => {

            var company_name;
            if(data.company_id){
                company_name = await dataProvider.getOne('companies', { id: data.company_id });
            }

            const transformedData = {
                ...data,
                company_name,
                updated_at: new Date(),
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
            };
            return transformedData;
        }}
    >
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <Box mr={2}>
                                        <Avatar />
                                    </Box>
                                    <AccountInputs />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
    </CreateBase>
);
}

export default AccountCreate;
