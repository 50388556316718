import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext,
    useEditContext,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Divider, Box, Grid, IconButton, Button, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { endpoint } from '../App';
import { ColorField, ColorInput } from 'react-admin-color-picker';
import { Link } from 'react-router-dom';

export const CompanyInputs = () => {
    const navigate = useNavigate();
    const editContext = useEditContext();

    return (<Box flex="1" mt={-1}>

        <Box display="flex" flexDirection="row">
			<IconButton
				  onClick={() => navigate(-1)}
				  size="small"
				>
				  <KeyboardBackspaceIcon />
			</IconButton>
            <h3>База данных</h3>
		</Box>

        <TextInput label="Название" source="name" fullWidth validate={required()} />

        {editContext.record ?
        <Typography variant="body1">База данных: "{editContext.record.db_name}"</Typography>
        :
        <TextInput label="Наименование для базы данных" source="db_name" helperText="camelCase, латинскими буквами, нельзя изменить позже" fullWidth validate={required()} /> }
        
        {/*<Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
            <Box>
                <h3>Разделы (сущности)</h3>
            </Box>
        <Box ml={5}>
            <Link to={`/entities/create`}>
                <Button variant="contained" color="primary">
                + Новый раздел
                </Button>
            </Link>
        </Box>
        </Box>*/}

    </Box>);
};
