import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useEditContext,
    useListContext,
    Form,
    ArrayInput,
    SimpleFormIterator,
    ReferenceField,
    FunctionField,
    NumberInput,
    DateInput,
    FileInput,
    FileField,
    RadioButtonGroupInput,
    CheckboxGroupInput,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Divider, Box, Grid, IconButton, Button, Typography  } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';

const inputOptions = [
    { id: 'TextInput', name: 'TextInput' },
    { id: 'NumberInput', name: 'NumberInput' },
    { id: 'DateInput', name: 'DateInput' },
    { id: 'TimeInput', name: 'TimeInput' },
    { id: 'DateTimeInput', name: 'DateTimeInput' },
    { id: 'PasswordInput', name: 'PasswordInput' },
    { id: 'SelectInput', name: 'SelectInput' },
    { id: 'AutocompleteInput', name: 'AutocompleteInput' },
    { id: 'ReferenceInput', name: 'ReferenceInput' },
    { id: 'ReferenceArrayInput', name: 'ReferenceArrayInput' },
    { id: 'BooleanInput', name: 'BooleanInput' },
    { id: 'RadioButtonGroupInput', name: 'RadioButtonGroupInput' },
    { id: 'CheckboxGroupInput', name: 'CheckboxGroupInput' },
    { id: 'ArrayInput', name: 'ArrayInput' },
    { id: 'FileInput', name: 'FileInput' },
    { id: 'ImageInput', name: 'ImageInput' },
    { id: 'RichTextInput', name: 'RichTextInput' },
    { id: 'NumberFormatInput', name: 'NumberFormatInput' }
];

export const EntityInputs = () => {
    const navigate = useNavigate();
    const editContext = useEditContext();

    return (<Box flex="1" mt={-1}>

        <Box display="flex" flexDirection="row">
			<IconButton
				  onClick={() => navigate(-1)}
				  size="small"
				>
				  <KeyboardBackspaceIcon />
			</IconButton>
            <h3>Таблица</h3>
		</Box>

        <TextInput label="Название" source="name" fullWidth validate={required()} />

        {editContext.record ?
        <Typography variant="body1">Таблица в базе данных: "{editContext.record.table_name}"</Typography>
        :
        <TextInput label="Наименование для базы данных" source="table_name" helperText="camelCase, латинскими буквами, нельзя изменить позже" fullWidth multiline validate={required()} />}

        {editContext.record ?
        <ReferenceField label="Компания" reference="companies" source="company_id">
            <FunctionField
                ml={1}
                render={record => (`${record.name}`)}
            />
         </ReferenceField>   
        :
        <ReferenceInput source="company_id" reference="companies">
                <AutocompleteInput label="База данных в которой будет таблица" optionText={(choice) => `${choice.name}`} helperText="Нельзя изменить позже" fullWidth sx={{ marginTop: 2 }} validate={required()} />
        </ReferenceInput>
        }

        <ArrayInput label="Поля" source="fields" sx={{ marginTop: 2 }}>
        <SimpleFormIterator inline disableClear fullWidth>
            <SelectInput
                source="component"
                label="Поле"
                choices={inputOptions}
                optionText="name"
                optionValue="id"
                required
                fullWidth
            />
            <TextInput label="Название поля" source="label" fullWidth validate={required()} />
            <TextInput label="Название ресурса для базы данных" source="source" fullWidth validate={required()} />
        </SimpleFormIterator>
        </ArrayInput>

    </Box>);
};

/*
 <TextInput label="Имя" source="name" fullWidth validate={required()} />

 <TextInput label="Номер телефона" source="phone" fullWidth validate={required()} />

 <TextInput label="Телеграм-никнейм" source="tg_nickname" fullWidth validate={required()} />
*/