import * as React from 'react';
import { Menu, useRedirect, useGetList, } from 'react-admin';
import { Divider, Box, Grid, Paper, Button } from '@mui/material';
import BookIcon from '@mui/icons-material/Book';
import PeopleIcon from '@mui/icons-material/People';
import LabelIcon from '@mui/icons-material/Label';
import StoreIcon from '@mui/icons-material/Store';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FunnelIcon from '@mui/icons-material/FilterAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import EmailIcon from '@mui/icons-material/Email';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DraftsIcon from '@mui/icons-material/Drafts';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddLinkIcon from '@mui/icons-material/AddLink';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
// New icons
import { TbShoppingCart } from "react-icons/tb";
import { FaCartShopping } from "react-icons/fa6";
import { TbCheckbox } from "react-icons/tb";
import { TbBriefcase } from "react-icons/tb";
import { TbUsersGroup } from "react-icons/tb";
import { TbLayoutGridAdd } from "react-icons/tb";
import { TbUserCircle } from "react-icons/tb";
import { TbUsers } from "react-icons/tb";
import { TbFileAnalytics } from "react-icons/tb";
import { TbCashRegister } from "react-icons/tb";

import { FcConferenceCall } from "react-icons/fc";
import { FcBriefcase } from "react-icons/fc";
import { FcAcceptDatabase } from "react-icons/fc";
import { FcBarChart } from "react-icons/fc";
import { FcCustomerSupport } from "react-icons/fc";
import { FcMoneyTransfer } from "react-icons/fc";
import { FcPaid } from "react-icons/fc";
import { FcOrgUnit } from "react-icons/fc";
import { FcDocument } from "react-icons/fc";


const MyMenu = (props) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const [isHoveredAccounting, setIsHoveredAccounting] = React.useState(false);
    const redirect = useRedirect();

    const { data: funnels,  isLoading: isLoadingFunnels } = useGetList(
        'funnels',
        { 
            pagination: { page: 1, perPage: 5000 },
            sort: { field: 'updated_at', order: 'DESC' },
        }
    );

    const companyId = localStorage.getItem('company_id');
    const companyName = localStorage.getItem('company_name');

    // Fetch entities based on company_id
    const { data: entities, isLoading: isLoadingEntities } = useGetList(
        `entities`,
        {
            filter: { company_id: companyId }, // Filtering by company_id
            pagination: { page: 1, perPage: 5000 },
        }
    );

    const handleMenuItemClick = (id) => {
        redirect(`/requests?funnel_id=${id}`);
        //window.location.reload();
    };

    const handleMouseEnter = () => {
            setIsHovered(true);
    };

    const handleMouseLeave = () => {
            setIsHovered(false);
    };

    const handleMouseEnterAccounting = () => {
        setIsHoveredAccounting(true);
    };

    const handleMouseLeaveAccounting = () => {
        setIsHoveredAccounting(false);
    };

    return (
    <Menu {...props}>
         {/* <Menu.DashboardItem /> */}
        {/* entities && entities.length < 1 && 
        <>
        <Menu.Item to="/companies" primaryText="Компании" leftIcon={<FcConferenceCall size={24} />}/>
        <Menu.Item to="/entities" primaryText="Сущности" leftIcon={<FcOrgUnit size={24} />} />
        </>
        */}

        <Menu.Item to="/companies" primaryText="Базы данных" leftIcon={<FcConferenceCall size={24} />}/>
        <Menu.Item to="/entities" primaryText="Таблицы" leftIcon={<FcOrgUnit size={24} />} />
        <Menu.Item to="/users" primaryText="Пользователи" leftIcon={<FcCustomerSupport size={24} />}/>

        {/* <Box height={20} component="span" />*/}

        {/* <Menu.Item to="/companies" primaryText="Компании" leftIcon={<StoreIcon />}/> */}
        {/* localStorage.getItem('role_id') <= 2 ? <Menu.Item to="/tags" primaryText="Редактор тегов" leftIcon={<LocalOfferIcon />}/> : null*/}

        {entities && entities.map((entity) => (
                <Menu.Item 
                    to={`/${entity.table_name}`}
                    key={entity.id} 
                    primaryText={entity.name} // Display entity name
                    leftIcon={<FcDocument size={24} />} // You can change the icon as needed
                />
            ))}
    </Menu>
)
};

export default MyMenu;