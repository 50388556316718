import React, { useState } from 'react';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import {
  Admin, 
  Resource, 
  fetchUtils, 
  useGetIdentity, 
  CustomRoutes, 
  defaultTheme, 
  nanoLightTheme, 
  nanoDarkTheme,
  radiantLightTheme, 
  radiantDarkTheme,
  houseLightTheme, 
  houseDarkTheme,
  useGetList,
} from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { authProvider } from './authProvider';
import axios from 'axios';
import { omit } from 'lodash';
import { Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';

import AccountList from './accounts/AccountList';
import AccountCreate from './accounts/AccountCreate';
import AccountEdit from './accounts/AccountEdit';
import AccountShow from './accounts/AccountShow';
import LunchButtons from './accounts/LunchButtons';

import LogsList from './logs/LogsList';
import LogShow from './logs/LogShow';

import NotificationsList from './notifications/NotificationsList';
import Notifications from './notifications/Notifications';

import MyLayout from './design/MyLayout';
import MyLoginPage from './design/MyLoginPage';
import SettingsPage from './design/SettingsPage';
import ThemeSettings from './design/ThemeSettings';
import { themes, lightTheme, darkTheme } from './design/themes';

import EntityList from './entities/EntityList';
import EntityCreate from './entities/EntityCreate';
import EntityEdit from './entities/EntityEdit';

import CompanyList from './companies/CompanyList';
import CompanyCreate from './companies/CompanyCreate';
import CompanyEdit from './companies/CompanyEdit';

import { createEntityList, createEntityCreate, createEntityEdit } from './dynamicComponents/dynamicComponents';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

const fetchJson = (url, options = {}) => {
    options.user = {
        authenticated: true,
        token: localStorage.getItem('token'),
    };
    // Ensure headers object exists
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }

    // Set user authentication token and company ID
    options.headers.set('Authorization', `${localStorage.getItem('token')}`);
    options.headers.set('X-Company-Name', localStorage.getItem('company_name'));

    //options.headers.set('X-Company-Id',  localStorage.getItem('company_name'));
    return fetchUtils.fetchJson(url, options);
};

async function convertFileToBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      const arrayBuffer = reader.result;
      resolve(arrayBuffer);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

export const endpoint = "https://skadle.com";
export const wsEndpoint = "wss://skadle.com/ws";

const dataProvider = simpleRestProvider(endpoint, fetchJson);

const MyDataProvider = {
   ...dataProvider,
  create: async (resource, params) => {
    if (params.data.file) {
      const formData = new FormData();
    
      // Dynamically append all fields from params.data to formData
      Object.entries(params.data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          // Check if the array contains objects (e.g., estates)
          if (typeof value[0] === 'object' && value[0] !== null) {
            // Handle array of objects
            value.forEach((item, index) => {
              Object.entries(item).forEach(([subKey, subValue]) => {
                formData.append(`${key}[${index}][${subKey}]`, subValue);
              });
            });
          } else {
            // Handle simple arrays (e.g., array of strings or numbers)
            value.forEach((item, index) => {
              formData.append(`${key}[${index}]`, item);
            });
          }
        } else {
          // Handle the 'file' key separately for raw file upload
          formData.append(key, key === 'file' ? value.rawFile : value);
        }
      });
    
      // Ensure tableName is also added
      formData.append("tableName", resource);
    
      try {
        const response = await fetch(`${endpoint}/upload`, {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': localStorage.getItem('token'),
          },
        });
    
        const json = await response.json();
        return { data: json };
      } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
      }
    }

    // Fallback to the default create method for other resources
    return dataProvider.create(resource, params);
  },
  update: async (resource, params) => {
      if (params.data.file){
        const formData = new FormData();
    
        // Dynamically append all fields from params.data to formData
        Object.entries(params.data).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            // Check if the array contains objects (e.g., estates)
            if (typeof value[0] === 'object' && value[0] !== null) {
              // Handle array of objects
              value.forEach((item, index) => {
                Object.entries(item).forEach(([subKey, subValue]) => {
                  formData.append(`${key}[${index}][${subKey}]`, subValue);
                });
              });
            } else {
              // Handle simple arrays (e.g., array of strings or numbers)
              value.forEach((item, index) => {
                formData.append(`${key}[${index}]`, item);
              });
            }
          } else {
            // Handle the 'file' key separately for raw file upload
            formData.append(key, key === 'file' ? value.rawFile : value);
          }
        });
      
        // Ensure tableName is also added
        formData.append("tableName", resource);
  
        try {
          const response = await fetch(`${endpoint}/upload`, {
            method: "PUT",
            body: formData,
            headers: {
              'Authorization': `${localStorage.getItem('token')}`,
            },
          });
  
          const json = await response.json();
          return { data: json };
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      }

      return dataProvider.update(resource, params);
    }
};

const SotkaTheme = {
    ...defaultTheme,
    palette: {
        primary: {
          main: '#003801',
        },
        secondary: {
          //main: '#FF8100',
        },
  },
};


const App = () => {
  const [entities, setEntities] = useState([]);

  const fetchEntities = async () => {
      // Fetch entities based on company_id
      const companyId = localStorage.getItem('company_id');
      const token = localStorage.getItem('token');
      
      const response = await fetch(`${endpoint}/entities?company_id=${companyId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
        },
    });
      const data = await response.json();
      if(Array.isArray(data)){
      setEntities(data);
      }
  };

  React.useEffect(() => {
      fetchEntities();
  }, []);

  const createDynamicResources = (entities) => {
      return entities.map((entity) => {
          const EntityList = createEntityList(entity);
          const EntityCreate = createEntityCreate(entity);
          const EntityEdit = createEntityEdit(entity);

          return (
              <Resource
                  key={entity.id}
                  options={{ label: entity.name }}
                  name={entity.table_name}
                  list={EntityList}
                  create={EntityCreate}
                  edit={EntityEdit}
              />
          );
      });
  };

  return (
      <Admin
          dataProvider={MyDataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          layout={MyLayout}
          loginPage={MyLoginPage}
          theme={lightTheme}
          darkTheme={darkTheme}
      >
          <Resource options={{ label: 'Пользователи' }} name='users' list={AccountList} create={AccountCreate} edit={AccountEdit} show={AccountShow} />
          <Resource options={{ label: 'Сущности' }} name='entities' list={EntityList} create={EntityCreate} edit={EntityEdit} />
          <Resource options={{ label: 'Компании' }} name='companies' list={CompanyList} create={CompanyCreate} edit={CompanyEdit} />
          <Resource options={{ label: 'История изменений' }} name='logs' list={LogsList} show={LogShow} />
          <Resource options={{ label: 'Уведомления' }} name='notifications' list={NotificationsList} />
          {createDynamicResources(entities)}
          <CustomRoutes>
              <Route options={{ label: 'Настройки' }} path="/settings" element={<SettingsPage />} />
          </CustomRoutes>
      </Admin>
  );
};

export default App;