import React from 'react';
import { List, Datagrid, TextField, EditButton, Create, SimpleForm, Edit, required, SaveButton, Toolbar,
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, ImageInput, ImageField,
    useRecordContext,
    useEditContext,
    useListContext,
    Form,
    ArrayInput,
    SimpleFormIterator,
    ReferenceField,
    FunctionField,
    NumberInput,
    DateInput,
    FileInput,
    FileField,
    RadioButtonGroupInput,
    CheckboxGroupInput,
    useDataProvider,
    useRedirect,
    PasswordInput,
    DateTimeInput,
    Pagination,
    FilterLiveSearch,
 } from 'react-admin';
 import { Card, CardContent, Box, Button } from '@mui/material';

 const datagridComponentMap = {
    TextInput: TextField,
    PasswordInput: TextField,
    NumberInput: TextField,
    DateInput: TextField,
    DateTimeInput: TextField,
    ReferenceInput: ReferenceField,
    ImageInput: ImageField,
    FileInput: FileField,
    AutocompleteInput: TextField, // Use TextField for AutocompleteInput in Datagrid (or create a custom component if needed)
    BooleanInput: TextField, // Use TextField or create a custom BooleanField for Datagrid
    SelectInput: TextField, // Use TextField or create a custom SelectField for Datagrid
    RadioButtonGroupInput: TextField, // Use TextField or create a custom RadioButtonGroupField for Datagrid
    CheckboxGroupInput: TextField, // Use TextField or create a custom CheckboxGroupField for Datagrid
    ArrayInput: TextField // Use TextField or create a custom ArrayField for Datagrid
};

 const renderDatagridColumn = (field, index) => {
    const Component = datagridComponentMap[field.component];

    if (!Component) return null;

    return (
        <Component
            key={index}
            source={field.source}
            label={field.label}
            {...(field.props || {})}
        />
    );
};

export const createEntityList = (entity) => (props) => {
    return (
        <List {...props} resource={entity.table_name}
        perPage={50}
        filters={[<FilterLiveSearch alwaysOn />]}
        >
            <div style={{ marginBottom: '3rem' }}>
                <div style={{ position: 'absolute', top: 0, left: 0 }}>
                    <Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />
                </div>
            </div>
            <Datagrid bulkActionButtons={false} >
                {entity.fields.map((field, index) => renderDatagridColumn(field, index))}
                <EditButton />
            </Datagrid>
        </List>
    );
};

// Component map to map the string from config to the actual component
const componentMap = {
    TextInput: TextInput,
    ReferenceInput: ReferenceInput,
    AutocompleteInput: AutocompleteInput,
    BooleanInput: BooleanInput,
    SelectInput: SelectInput,
    NumberInput: NumberInput,
    DateInput: DateInput,
    DateTimeInput: DateTimeInput,
    PasswordInput: PasswordInput,
    ArrayInput: ArrayInput,
    ImageInput: ImageInput,
    ImageField: ImageField,
    FileInput: FileInput,
    FileField: FileField,
    RadioButtonGroupInput: RadioButtonGroupInput,
    CheckboxGroupInput: CheckboxGroupInput,
};

const renderComponent = (field, index) => {
    const Component = componentMap[field.component];

    if (!Component) return null;

    // Special handling for ArrayInput
    if (field.component === 'ArrayInput') {
        return (
            <ArrayInput key={index} source={field.source} label={field.label}>
                <SimpleFormIterator>
                    {field.subFields.map((subField, subIndex) => {
                        const SubComponent = componentMap[subField.component];
                        return (
                            <SubComponent
                                key={subIndex}
                                label={subField.label}
                                source={subField.source}
                                validate={subField.required ? [required()] : []}
                                {...(subField.props || {})}
                            />
                        );
                    })}
                </SimpleFormIterator>
            </ArrayInput>
        );
    }

    // Special handling for ReferenceInput
    if (field.component === 'ReferenceInput') {
        return (
            <ReferenceInput
                key={index}
                label={field.label}
                source={field.source}
                reference={field.reference}
            >
                <AutocompleteInput
                    optionText={(choice) => choice?.name || ''}
                    {...(field.props || {})}
                />
            </ReferenceInput>
        );
    }

    return (
        <Component
            key={index}
            label={field.label}
            source={field.source}
            fullWidth={true}
            validate={field.required ? [required()] : []}
            {...(field.props || {})}
        />
    );
};

export const createEntityCreate = (entity) => (props) => {
    return (
        <Create {...props} resource={entity.table_name}
        redirect="list"
        mutationMode="optimistic"
        transform={(data) => ({
            ...data,
            created_at: new Date(),
            ap_id: localStorage.getItem('id'),
            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
        })}
        >
            <SimpleForm>
                {entity.fields.map((field, index) => renderComponent(field, index))}
            </SimpleForm>
        </Create>
    );
};

// Function to create a dynamic Edit component
export const createEntityEdit = (entity) => (props) => {

    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const handleDelete = async (id) => {
        // Fetch the existing record to get the data before delete
        const existingRecord = await dataProvider.getOne(entity.table_name, { id });

        // Modify the data or perform additional actions
        const modifiedData = {
            ...existingRecord.data,
             ap_id: localStorage.getItem('id'),
             ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
             table: entity.table_name
        };

        // Use dataProvider to send the delete request with modified data
        await dataProvider.create('delete', { data: modifiedData });

        // You can also perform additional actions after delete if needed
        //console.log(`Deleted record with ID: ${id}`);
        redirect(`/${entity.table_name}`);
    };

    return (
        <Edit {...props} resource={entity.table_name}
        redirect="list"
        mutationMode="optimistic"
        transform={(data) => ({
            ...data,
            ap_id: localStorage.getItem('id'),
            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
        })}
        >
            <SimpleForm toolbar={<CustomToolbar onDelete={handleDelete} />}>
                {entity.fields.map((field, index) => renderComponent(field, index))}
            </SimpleForm>
        </Edit>
    );
};

const CustomToolbar = ({ onDelete }) => {
    const record = useRecordContext(); // Get the record context

    const handleDeleteClick = () => {
        if (record && record.id) {
            onDelete(record.id);
        }
    };

    return (
        <Toolbar>
            <SaveButton />
            <Box width={20} component="span" />
            <Button style={{ color: 'red' }} onClick={handleDeleteClick} >Удалить</Button>
        </Toolbar>
    );
};